/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
    Render the mobile leftmenu based on object array produced by MenuBuilder
    sourced from either GraphQL or Composer.

    Each node of object array structure has:
        level           1 for top, 2 for next level down, etc.
        parentPosition  parent's index within array (used for panel expansion test)
        icon            [[icon-name]] for pre-defined icons (composer only)
                        Without [[]], it's just name of image file
        label           menu text
        link            relative or absolute URL for where user navigates when item clicked
        submenu         if this menu item has a submenu,
                        this is an array of its submenu items
        linkStyle       what kind of thing it is.
                        LINKSTYLE_NORMAL - normal menu item and link
                        LINKSTYLE_ALTERNATE - menu link with alternate styling
                        LINKSTYLE_BUTTON - display the menu link like a button
                        LINKSTYLE_TITLE - it's a static title, not a menu link
                        LINKSTYLE_DIVIDER - it's a divider line, not a menu link
                        LINKSTYLE_WIDGET - it's a widget, not a menu link

 */
import React, { useState } from 'react';
import { array, object, func } from 'prop-types';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/Add';
import ExpandLessIcon from '@material-ui/icons/Remove';
import { withStyles } from '@material-ui/core/styles';
import NavMenuItem from './Items/NavMenuItem';

const styles = (theme) => {
    const mobileMenuBG =    theme && theme.palette && theme.palette.mobileMenuBG ? theme.palette.mobileMenuBG : null;
    const leftMenuBG = mobileMenuBG ? mobileMenuBG.leftMenuBG : null;

    return {
        navMenuRender: {
            width: '100%',
            backgroundColor: mobileMenuBG ? mobileMenuBG.child : null,
        },
        navMenu: {
            margin: '0',
            listStyle: 'none',
            padding: '0',
            '& .item-menu-link svg, .item-menu-link img': {
                marginLeft: '10px',
            },
        },
        navMenuItem: {
            display: 'block',
            paddingLeft: '0',
            backgroundColor: theme.palette.white,
            // borderBottom: `1px solid ${theme.palette.cta1}`,
        },
        expansionPanel: {
            color: theme.palette.white,
            boxShadow: 'none',
            background: mobileMenuBG ? mobileMenuBG.child : null,
            borderRadius: '0',
            '& ul div': {
                backgroundColor: mobileMenuBG ? mobileMenuBG.child : null,
            },
            '& .MuiButtonBase-root': {
                display: 'flex',
            },
        },
        summaryPanel: {
            backgroundColor: theme.palette.white,
            padding: '0',
            minHeight: 'auto',
            borderRadius: '0',
            '&$expandedPanel': {
                minHeight: '34px',
                // borderBottom: `1px solid ${theme.palette.cta1}`,
            },
        },
        disabledPanel: {
            height: '34px',
            margin: '0',
        },
        expandedContent: {
            margin: '0',
        },
        expandedPanel: {
            color: theme.palette.white,
            minHeight: '34px',
            borderRadius: '0',
            '& div': {
                color: theme.palette.white,
                background: mobileMenuBG ? mobileMenuBG.parent : null,
            },
            '& div + div': {
                background: 'none',
            },
        },
        contentPanel: {
            margin: '0',
            '&$expandedPanel': {
                margin: '0',
            },
        },
        expandMoreIcon: {
            color: theme.palette.linkMenu,
        },
        lessIcon: {
            width: '30px',
            height: '34px',
            right: '0',
            color: theme.palette.linkMenu,
            marginRight: '0',
        },
        submenuPanel: {
            padding: '0 0 0 10px',
            '&$link': {
                background: 'grey1',
            },
            borderRadius: '0',
        },
        link: {
            color: theme.palette.linkMenu,
            display: 'block',
            fontSize: '18px',
            height: '34px',
            lineHeight: '34px',
            textDecoration: 'none',
            position: 'relative',
            fontWeight: '700',
        },
        rightIcon: {
            position: 'absolute',
            right: '7px',
            top: '4px',
        },
        dividerClassRaised: {
            height: '20px',
            width: '100%',
            display: 'flex',
            flexDirection: 'vertical',
            background: theme.palette.white,
            '& ~ li': {
                backgroundColor: leftMenuBG,
                '& > div': {
                    backgroundColor: leftMenuBG,
                },
                '& span': {
                    color: theme.palette.white,
                },
            },
        },
    };
};

const handleChange = (currentLevel, currentPosition, expandedPanels, setExpandedPanels) => () => {
    const openPanels = [...expandedPanels];

    if (currentLevel > openPanels.length - 1) {
        openPanels.push(currentPosition);
    } else if (openPanels[currentLevel] === currentPosition) {
        // Current position click is in the array meant that user is closing the menu.
        openPanels.splice(currentLevel, openPanels.length);
    } else {
        // Is because 'currentLevel <= expandedPanels.length - 1'
        openPanels.splice(currentLevel, openPanels.length);
        openPanels.push(currentPosition);
    }
    setExpandedPanels(openPanels);
};
const isLevelExpanded = (level, position, expandedPanels) => {
    const openPanels = [...expandedPanels];

    return level <= openPanels.length - 1 && openPanels[level] === position;
};
const navMenuRender = ({
    navMenuList, handleClose, classes, categoryLabel, brand, expandedPanels, setExpandedPanels,
}) => (
    <div className={classes.navMenuRender}>
        <ul className={classes.navMenu}>
            {navMenuList.map((menu, indexMenu) => {
                const keyMenu = `menu_${menu.level}_${indexMenu}`;
                const keyItem = `menuItem_${menu.level}_${indexMenu}`;
                const hasSubmenu = menu.submenu !== undefined && menu.submenu !== null;
                // Reviewing if the panel might be opened.
                const isOpen = isLevelExpanded(menu.level, indexMenu, expandedPanels);
                let tabIndex = -1;
                if (menu.level === 0 || expandedPanels[menu.level - 1] === menu.parentPosition) {
                    tabIndex = 0;
                }

                return (
                    <li className={classes.navMenuItem} key={keyMenu}>
                        {hasSubmenu ? (
                            <ExpansionPanel
                                expanded={isOpen}
                                className={classes.expansionPanel}
                                onChange={handleChange(menu.level, indexMenu, expandedPanels, setExpandedPanels)}
                            >
                                <ExpansionPanelSummary
                                    tabIndex=""
                                    classes={{
                                        root: classes.summaryPanel,
                                        expanded: classes.expandedPanel,
                                        content: classes.contentPanel,
                                        disabled: classes.disabledPanel,
                                        expandIcon: classes.lessIcon,
                                    }}
                                    expandIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                >
                                    <NavMenuItem
                                        key={keyItem}
                                        itemLabel={menu.label}
                                        itemLink={menu.link}
                                        hasSubmenu={hasSubmenu}
                                        tabIndexMenu={tabIndex}
                                        handleClose={handleClose}
                                        linkStyle={menu.linkStyle}
                                        brand={brand}
                                        level={menu.level}
                                        categoryLabel={categoryLabel}
                                    />
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.submenuPanel}>
                                    {navMenuRender({
                                        navMenuList: menu.submenu, handleClose, classes, categoryLabel: menu?.label, expandedPanels, setExpandedPanels,
                                    })}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ) : (
                            <NavMenuItem
                                key={keyItem}
                                itemLabel={menu.label}
                                itemLink={menu.link}
                                icon={menu.icon}
                                hasSubmenu={false}
                                tabIndexMenu={tabIndex}
                                handleClose={handleClose}
                                linkStyle={menu.linkStyle}
                                brand={brand}
                                level={menu?.level}
                                categoryLabel={categoryLabel}
                            />
                        )}
                    </li>
                );
            })}
        </ul>
    </div>
);
const NavMenu = ({
    navMenuList, classes, handleClose, brand,
}) => {
    const [expandedPanels, setExpandedPanels] = useState([]);

    // Save the opened panels into the state for review which one need to be open or closed.

    return navMenuRender({
        navMenuList, handleClose, classes, brand, expandedPanels, setExpandedPanels,
    });
};

NavMenu.propTypes = {
    navMenuList: array.isRequired,
    classes: object.isRequired,
    handleClose: func.isRequired,
    brand: object,
};
NavMenu.defaultProps = {
    brand: {},
};

export default withStyles(styles)(NavMenu);
