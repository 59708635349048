/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import Cookies from 'universal-cookie';
import { getAnalyticsStorageData } from '../../../../../helpers/tracking/session/sessionTrackingHelpers';

export const brandNames = ['1800baskets', '1800flowers', 'cheryls', 'fruitbouquets', 'harryanddavid', 'personalizationmall', 'berries', 'simplychocolate', 'stockyards', 'thepopcornfactory', 'vitalchoice', 'wolfermans', 'thingsremembered'];

export const getBrandNameFromURL = (link) => {
    let brandName = '';
    try {
        const brandUrl = new URL(link);
        const urlSplit = brandUrl?.host?.split?.('.');
        brandName = urlSplit[1];
    } catch { // no need to catch just avoid to throw error if url is invalid or not absolute
        brandName = null;
    }
    return brandName;
};

export const getBrandHost = (brand) => {
    const currentHost = window?.location?.host;
    const domainBrandTab = getBrandNameFromURL(brand);
    const brandHost = currentHost.split('.');

    if (brandHost?.length === 3) {
        brandHost[1] = domainBrandTab;
    } else if (brandHost?.length === 1) {
        // if doesn't split is localhost:port
        brandHost[0] = `pwa.${domainBrandTab}.${currentHost}`;
    }

    return brandHost.join('.');
};

export const isBrandLink = (itemLink = '') => {
    if (itemLink.indexOf('://') !== -1 && brandNames.some((brand) => itemLink.indexOf(brand) >= 0)) {
        return true;
    }
    return false;
};

export const handleBrandLinkClick = (e, itemLink, trackEvent, currentBrand, ffIsAttributionBrandTabsSupportEnabled) => {
    trackEvent({
        eventCategory: 'Cross Brand Nav', eventAction: 'Click', eventLabel: getBrandNameFromURL(itemLink),
    });

    if (typeof window !== 'undefined') {
        e.preventDefault();
        e.stopPropagation();

        const isAllowBrand = (itemLink?.indexOf('personalizationmall') === -1 && itemLink?.indexOf('goodsey') === -1 && itemLink?.indexOf('blog') === -1 && itemLink?.indexOf('articles') === -1);
        if (isAllowBrand) {
            const cookies = new Cookies();
            const entryBrandCookie = cookies.get('entryBrand') || currentBrand?.domain || '';
            const bannerCode = cookies.get('banner')?.c || '';
            // Set the entryBrand that the domain have currently
            const reqObj = [];

            if (ffIsAttributionBrandTabsSupportEnabled) {
                // Append full attribution to URL for both backend systems (r) and Tealium (utms/gclid)
                const analyticsSession = getAnalyticsStorageData();

                if (analyticsSession
                    && (analyticsSession.landingParams && Object.keys(analyticsSession.landingParams).length > 0)
                ) {
                    const attributionParamsQueryString = new URLSearchParams(analyticsSession.landingParams).toString();

                    reqObj.push(attributionParamsQueryString);
                }
            } else if (bannerCode) {
                reqObj.push(`r=${bannerCode}`);
            }

            if (entryBrandCookie) {
                reqObj.push(`entryBrand=${entryBrandCookie}`);
            }

            if (bannerCode
                && !reqObj.includes(`r=${bannerCode}`) // checking if r param not exist from line 131
            ) {
                reqObj.push(`r=${bannerCode}`);
            }
            const brandHost = getBrandHost(itemLink);
            window.location.href = `${window.location.protocol}//${brandHost}/?${reqObj.join('&')}`;
        } else {
            window.location.href = itemLink;
        }
    }
};

export default {};
