/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
    Render the horizontal desktop header nav menu based on object array produced by MenuBuilder
    sourced from CMS/GraphQL.

    Each node of object array structure has:
        label           menu text for top menu item
        link            relative or absolute URL for where user navigates when item clicked (if no submenu)
        submenu         if this menu item has a submenu,
                        this is an array of its submenu items

    Each submenu node has:
        label           menu text
        link            relative or absolute URL for where user navigates when item clicked
        linkStyle       what kind of thing it is.
                        LINKSTYLE_NORMAL - normal menu item and link
                        LINKSTYLE_ALTERNATE - menu link with alternate styling
                        LINKSTYLE_HEADING - heading style; link optional
                        LINKSTYLE_BUTTON - display the menu link like a button
                        LINKSTYLE_TITLE - it's a static title, not a menu link
                        LINKSTYLE_DIVIDER - it's a divider line, not a menu link
                        LINKSTYLE_WIDGET - it's a widget, not a menu link

*/
import React from 'react';
import {
    array, object, shape, string,
} from 'prop-types';
import { UIDReset, UIDConsumer } from 'react-uid';

import { withStyles } from '@material-ui/core/styles';
import loadable from '@loadable/component';
import customBreakpoints from '../../../../../helpers/customBreakpoints';

const DesktopGqlTopNavMenuItem = loadable(() => import(/* webpackChunkName: "DesktopGqlTopNavMenuItem" */ './Items/DesktopTopNavMenuItem'));

const styles = (theme) => ({
    desktopNavMenu: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        margin: 0,
        padding: '0',
        alignItems: 'flex-end',
        height: '100%',
        width: '100%',
        [theme.breakpoints.down('1130')]: {
            justifyContent: 'space-around',
        },
    },
    navMenuContainer: {
        maxWidth: '1400px',
        width: '100%',
    },

    alternateNavMenu: {
        listStyle: 'none',
        height: '100%',
        position: 'relative',
        '@media screen and (min-width: 1024px) and (max-width: 1075px)': {
            margin: '0 0 0 -40px',
            padding: '0',
            '& img': {
                width: '20px',
                height: '20px',
            },
        },
    },
    alternateNavMenuItem: {
        display: 'flex',
        height: '100%',
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            display: 'none',
        },

    },
    adjustPadding: {
        '& li:not(:first-of-type):not(:last-of-type)': {
            padding: '0 20px',
        },
    },
});
const LINKSTYLE_ALTERNATE = 'Alternate';
let adjustPadding = '';
const DesktopNavMenu = (props) => {
    const {
        navMenuList, attributes, classes, brand, presentation_family,
    } = props;
    const alternateMenuList = [];
    let primaryMenuList = navMenuList;
    if (presentation_family === 'food') {
        primaryMenuList = navMenuList.filter((menu) => {
            let response = true;
            if (menu.linkStyle === LINKSTYLE_ALTERNATE) {
                alternateMenuList.push(menu);
                response = false;
            }
            return response;
        });
    }
    const flexJustifyDirection = (direction) => {
        adjustPadding = classes.adjustPadding;
        switch (direction) {
            case 'Left':
                return 'flex-start';

            case 'Center':
                return 'center';

            case 'Right':
                return 'flex-end';

            default:
                return 'center';
        }
    };

    // Override styles of menus and container from contentstack
    const containerStyleOverride = {};
    const menuStyleOverride = {};
    Object.keys(attributes).forEach((attribute) => {
        if (attributes[attribute]) {
            switch (attribute) {
                case 'secondary':
                    menuStyleOverride.justifyContent = 'space-around';
                    break;
                case 'menu_width':
                    menuStyleOverride.width = attributes[attribute];
                    break;
                case 'align_menu_items':
                    menuStyleOverride.justifyContent = flexJustifyDirection(attributes[attribute]);
                    break;
                case 'padding':
                    containerStyleOverride.padding = `0 ${attributes[attribute]}px`;
                    break;
                case 'additional_height':
                    containerStyleOverride.top = `-${attributes[attribute]}px`;
                    break;
                default:
                    break;
            }
        }
    });
    const menuItemIterator = (menuList) => (
        <UIDReset>
            <UIDConsumer>
                {(id, uid) => menuList.map(
                    (topMenuItem, menuIndex) => (
                        <DesktopGqlTopNavMenuItem
                            key={uid(topMenuItem)}
                            item={topMenuItem}
                            attributes={attributes}
                            brand={brand}
                            index={menuIndex + 1}
                            length={navMenuList.length}
                            label={topMenuItem.label}
                            className={classes.topMenuItem}
                        />
                    ),
                )}
            </UIDConsumer>
        </UIDReset>
    );

    return (
        <div style={containerStyleOverride} className={`${classes.navMenuContainer} ${adjustPadding}`}>
            <ul style={menuStyleOverride} className={classes.desktopNavMenu}>
                {menuItemIterator(primaryMenuList)}
                {alternateMenuList?.length > 0
            &&  (
                <li className={classes.alternateNavMenu}>
                    <ul className={classes.alternateNavMenuItem}>
                        {menuItemIterator(alternateMenuList)}
                    </ul>
                </li>
            )}
            </ul>
        </div>
    );
};

DesktopNavMenu.propTypes = {
    navMenuList: array.isRequired,
    attributes: object,
    classes: object.isRequired,
    presentation_family: string.isRequired,
    brand: shape({
        code: string.isRequired,
    }).isRequired,
};

DesktopNavMenu.defaultProps = {
    attributes: {},
};

export default withStyles(styles)(DesktopNavMenu);
