/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { Component } from 'react';
import {
    object, func, string, shape, bool,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import memberDucks from '../../../../../../../../state/ducks/Member/ducks';
import { getPresentationFamily } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const {
    common: {
        commonSelectors,
    },
} = memberDucks;
const styles = (theme) => ({
    signInIcon: {
        height: '23px',
        width: '23px',
        pointerEvent: 'none',
        display: 'inline-block',
    },
    signIn: {
        fontSize: 16,
        fontWeight: 'bold',
        display: 'block',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        backgroundColor: theme.palette.colorNeutral20,
        padding: '0 5px',
        marginTop: 2,
        webkitBoxShadow: '0px -4px 3px rgba(100, 100, 100, 0.21)',
        mozBoxShadow: '0px -4px 3px rgba(100, 100, 100, 0.21)',
        boxShadow: '0px -4px 3px rgba(100, 100, 100, 0.21)',
        '&:first-child': {
            borderRadius: 0,
        },
    },
    panelDetails: {
        fontSize: 14,
        margin: '10px 8px',
        '& a': {
            textDecoration: 'none',
        },
    },
    panelDescription: {
        '& div': {
            justifyContent: 'center',
        },
    },
});

class LoginDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    handleChange =  (panel) => (event, expanded) => {
        if (expanded) {
            this.setState({ expanded: panel });
        } else {
            this.setState({ expanded: false });
        }
    }

    render() {
        const {
            classes, handleClose, getFullName, isAuthenticated, presentation_family,
        } = this.props;
        const { expanded } = this.state;
        const shortenFit = (s) => (s.length <= 6 ? s : `${s.substr(0, 6)}…`);
        if (isAuthenticated && presentation_family === 'flower') {
            return (
                <div className={classes.container}>

                    <ExpansionPanel className={classes.signIn} expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelDescription}>
                            <svg
                                viewBox="0 0 24 24"
                                preserveAspectRatio="none"
                                fill="#1f1f1b"
                                focusable="false"
                                className={classes.signInIcon}
                            >
                                <g>
                                    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                                </g>
                            </svg>
                            Hi&nbsp;{isAuthenticated &&  getFullName?.firstName !== '' ? shortenFit(getFullName.firstName) :  null}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.panelDetails}>
                            <Link onClick={handleClose} to="/account">My Account</Link>
                        </ExpansionPanelDetails>
                        <ExpansionPanelDetails className={classes.panelDetails}>
                            <a onClick={handleClose} href="/account/orders">My Orders</a>
                        </ExpansionPanelDetails>
                        <ExpansionPanelDetails className={classes.panelDetails}>
                            <Link onClick={handleClose} to="/auth/logout">Sign Out</Link>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            );
        }
        return null;
    }
}
const mapStateToProps = (state) => ({
    getFullName: commonSelectors.getFirstLastName(state),
    isAuthenticated: commonSelectors.getIsAuthenticatedStatus(state),
    presentation_family: getPresentationFamily(state),
});
LoginDropdown.propTypes = {
    classes: object.isRequired,
    handleClose: func.isRequired,
    presentation_family: string.isRequired,
    getFullName: shape({
        firstName: string.isRequired,
        lastName: string.isRequired,
    }).isRequired,
    isAuthenticated: bool.isRequired,
};
export default connect(mapStateToProps, null)(withStyles(styles)(LoginDropdown));
